import { uniqueId, sample } from "lodash-es";
import { DateTime } from "luxon";
import characters from "./characters";

// GameManager responsible for updating game
// all methods need to be asynch
// support offline play with JS objects, or online play with API requests
export class GameManager {
  game = null;

  type = "offline"; // online, or offline

  onlinePlayers = [];

  constructor(data) {
    this.type = data?.type === "online" ? "online" : "offline";

    if (this.type === "offline") {
      this.game = new Game();
    } else {
      // assume initiator local player is player 1
      // must wait for player 2
      //
    }
  }

  get pieces() {
    return this.game?.pieces ?? null;
  }

  get board() {
    return this.game?.board ?? null;
  }

  get players() {
    return this.game?.players ?? null;
  }

  get startPlayerId() {
    return this.game?.startPlayerId ?? null;
  }

  get notStartPlayerId() {
    return this.game?.notStartPlayerId ?? null;
  }

  get nextPieceId() {
    return this.game?.nextPieceId ?? null;
  }

  get confirmedNextPiece() {
    return this.game?.confirmedNextPiece ?? null;
  }
  ////

  get piecesNotPlayed() {
    return this.game?.piecesNotPlayed ?? null;
  }

  get playerOne() {
    return this.game?.playerOne ?? null;
  }

  get playerTwo() {
    return this.game?.playerTwo ?? null;
  }

  get currentChooserId() {
    return this.game?.currentChooserId ?? null;
  }

  get turns() {
    return this.game?.turns ?? null;
  }

  get winnerId() {
    return this.game?.winnerId ?? null;
  }

  get rowsColumnsDiagonals() {
    return this.game?.rowsColumnsDiagonals ?? null;
  }

  get isDraw() {
    return this.game?.isDraw ?? null;
  }

  get isComplete() {
    return this.game?.isComplete ?? null;
  }

  getPieceById(id) {
    return this.game?.getPieceById(id) ?? null;
  }

  getPlayerById(id) {
    return this.game?.getPlayerById(id) ?? null;
  }

  async confirmNextPiece() {
    return (await this.game?.confirmNextPiece()) ?? false;
  }

  async selectNextPieceId(pieceId) {
    return (await this.game?.selectNextPieceId(pieceId)) ?? false;
  }

  async placePiece(row, col) {
    return (await this.game?.placePiece(row, col)) ?? false;
  }
}

export class Game {
  attributes = {
    color: ["gold", "silver"],
    size: ["small", "large"],
    fill: ["logo", "blank"],
    shape: ["square", "circle"],
  };
  pieces = [];
  board = [
    [null, null, null, null],
    [null, null, null, null],
    [null, null, null, null],
    [null, null, null, null],
  ];
  players = [];
  startPlayerId;
  notStartPlayerId;
  nextPieceId = null;
  confirmedNextPiece = false;

  constructor(data) {
    if (data?.players?.length) {
      this.players = [new Player(data?.players?.[0]), new Player(data?.players?.[1])];
    } else {
      let firstChoice = sample(characters);
      let secondChice = sample(characters.filter((a) => a.name !== firstChoice.name));
      this.players = [new Player(firstChoice), new Player(secondChice)];
    }

    this.startPlayerId = data?.startPlayerId ?? sample(this.players).id;
    this.notStartPlayerId =
      data?.notStartPlayerId ?? this.players.find((p) => p.id !== this.startPlayerId).id;

    if (data?.pieces?.length) {
      this.pieces = data?.pieces?.map((p) => new Piece(p));
    } else {
      let pieceTypes = [];
      let counter = 0;
      this.attributes.color.forEach((color) => {
        this.attributes.size.forEach((size) => {
          this.attributes.fill.forEach((fill) => {
            this.attributes.shape.forEach((shape) => {
              pieceTypes = [
                ...pieceTypes,
                new Piece({
                  color,
                  size,
                  fill,
                  shape,
                  intitialGridPosition: counter,
                }),
              ];
              counter++;
            });
          });
        });
      });
      this.pieces = pieceTypes;
    }
  }

  get piecesNotPlayed() {
    return this.pieces.filter((piece) => {
      return !this.turns.find((t) => t.pieceId === piece.id);
    });
  }

  get playerOne() {
    return this.players?.[0];
  }

  get playerTwo() {
    return this.players?.[1];
  }

  get currentChooserId() {
    if (this.winnerId || this.isDraw) {
      return null;
    }

    // if the board is empty, return the startingPlayerId
    // otherwise return the
    if (!this.turns.length) {
      return this.startPlayerId;
    }

    return this.turns?.[0]?.playerId;
  }

  get currentPlacerId() {
    if (this.winnerId || this.isDraw) {
      return null;
    }

    // the player who is not the chooser
    return this.players.find((p) => p.id !== this.currentChooserId).id;
  }

  get turns() {
    let allTurns = [];

    this.board.forEach((row) => {
      row.forEach((col) => {
        if (col) {
          allTurns = [...allTurns, col];
        }
      });
    });

    return allTurns
      .sort((a, b) => {
        return a.placedAt > b.placedAt ? -1 : 1;
      })
      .map((t) => new Turn(t));
  }

  get winnerId() {
    if (!this.winningSet) {
      return null;
    }

    let sortSet = [...this.winningSet];

    // sort the winningSet by turn date
    // return the id of the newest placed turn
    return sortSet.sort((a, b) => {
      return a.placedAt > b.placedAt ? -1 : 1;
    })[0].playerId;
  }

  get winningSet() {
    let allSets = [...this.rowsColumnsDiagonals];

    // loop through each rowColumnDiagonal and check if a match
    // return the matched row

    let foundSet = allSets.find((set) => {
      let pieces = set.map((s) => s.piece);
      let foundColor = matchProperty("color", pieces);
      let foundSize = matchProperty("size", pieces);
      let foundShape = matchProperty("shape", pieces);
      let foundFill = matchProperty("fill", pieces);

      if (foundColor || foundSize || foundShape || foundFill) {
        return true;
      }

      return false;
    });

    return foundSet ?? null;
  }

  get rowsColumnsDiagonals() {
    // first add every row
    let allRows = [...this.board.map((row) => row)];

    // then add every column
    for (let r = 0; r < this.board.length; r++) {
      let rowSet = [];
      for (let c = 0; c < this.board[0].length; c++) {
        // allRows = [...allRows, []]
        rowSet = [...rowSet, this.board[c][r]];
      }
      allRows = [...allRows, rowSet];
    }

    allRows = [
      ...allRows,
      [this.board[0][0], this.board[1][1], this.board[2][2], this.board[3][3]],
      [this.board[3][0], this.board[2][1], this.board[1][2], this.board[0][3]],
    ];

    return allRows.map((row) =>
      row.map((turn) => ({
        ...turn,
        piece: this.getPieceById(turn?.pieceId) ?? null,
      }))
    );
  }

  get isDraw() {
    if (this.turns.length === 16 && !this.winnerId) {
      return true;
    }

    return false;
  }

  get isComplete() {
    return this.isDraw || !!this.winnerId;
  }

  // UTILITIES
  getPieceById(id) {
    return this.pieces.find((p) => p.id === id);
  }

  getPlayerById(id) {
    return this.players.find((p) => p.id === id);
  }

  // GAME ACTIONS
  confirmNextPiece() {
    if (!this.nextPieceId) {
      return false;
    }

    this.confirmedNextPiece = true;
    return true;
  }

  selectNextPieceId(pieceId) {
    // allow toggling piece Id
    if (this.confirmedNextPiece) {
      return false;
    }

    if (this.nextPieceId === pieceId) {
      this.nextPieceId = null;
      return true;
    }

    if (this.winnerId || this.isDraw) {
      return false;
    }

    if (!this.piecesNotPlayed.find((p) => p.id === pieceId)) {
      return false;
    }

    this.nextPieceId = pieceId;

    return true;
  }

  placePiece(row, col) {
    if (this.winnerId || this.isDraw) {
      return false;
    }

    if (!this.confirmedNextPiece) {
      return false;
    }

    if (!this.nextPieceId) {
      return false;
    }

    if (this.board[row][col]) {
      return false;
    }

    let turn = new Turn({
      pieceId: this.nextPieceId,
      playerId: this.currentPlacerId,
    });
    this.board[row][col] = turn;

    this.nextPieceId = null;
    this.confirmedNextPiece = false;

    return true;
  }
}

export class Piece {
  id;
  color;
  size;
  fill;
  shape;
  intitialGridPosition;

  constructor(data) {
    this.id = data?.id ?? uniqueId("piece_");
    this.color = data.color;
    this.size = data.size;
    this.fill = data.fill;
    this.shape = data.shape;
    this.intitialGridPosition = data.intitialGridPosition;
  }
}
export class Player {
  id;
  name;
  image;
  description;
  confirmed = true;

  constructor(data) {
    this.id = data?.id ?? uniqueId("user_");
    this.name = data?.name ?? `Player ${this.id}`;
    this.image = data?.image;
    this.description = data?.description;
    this.confirmed = data?.confirmed === false ? false : true;
  }
}

export class Turn {
  playerId;
  pieceId;
  placedAt;

  constructor(data) {
    this.placedAt = data?.placedAt ?? DateTime.now();
    this.playerId = data.playerId;
    this.pieceId = data.pieceId;
  }
}

const matchProperty = (prop, list = []) => {
  // if any cell is null/false, return false
  if (list.filter((c) => c).length !== list.length) {
    return false;
  }

  let propVals = {};

  list.forEach((l) => {
    propVals[l[prop]] = true;
  });

  let keys = Object.keys(propVals);

  return keys.length === 1;
};
