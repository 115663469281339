const characters = [
  {
    name: "Nigel",
    description: null,
    image: require("@/assets/characters/feudal-nigel-avatar.png"),
  },
  {
    name: "Amanirenas",
    description: null,
    image: require("@/assets/characters/society-amanirenas-avatar.png"),
  },
  {
    name: "Mary Anning",
    description: null,
    image: require("@/assets/characters/society-anning-avatar.png"),
  },
  {
    name: "Ashoka",
    description: null,
    image: require("@/assets/characters/society-ashoka-avatar.png"),
  },
  {
    name: "Blackbeard",
    description: null,
    image: require("@/assets/characters/society-blackbeard-avatar.png"),
  },
  {
    name: "Copernicus",
    description: null,
    image: require("@/assets/characters/society-copernicus-avatar.png"),
  },
  {
    name: "Leonardo Da Vinci",
    description: null,
    image: require("@/assets/characters/society-davinci-avatar.png"),
  },
  {
    name: "Epicurus",
    description: null,
    image: require("@/assets/characters/society-epicurus-avatar.png"),
  },
  {
    name: "Stephan Farffler",
    description: null,
    image: require("@/assets/characters/society-farffler-avatar.png"),
  },
  {
    name: "Harriet Tubman",
    description: null,
    image: require("@/assets/characters/society-tubman-avatar.png"),
  },
  {
    name: "Hypatia",
    description: null,
    image: require("@/assets/characters/society-hypatia-avatar.png"),
  },
  {
    name: "Ikkyu",
    description: null,
    image: require("@/assets/characters/society-ikkyu-avatar.png"),
  },
  {
    name: "Joan of Arc",
    description: null,
    image: require("@/assets/characters/society-joan-of-arc-avatar.png"),
  },
  {
    name: "Marie Curie",
    description: null,
    image: require("@/assets/characters/society-marie-curie-avatar.png"),
  },
  {
    name: "Queen Nanny",
    description: null,
    image: require("@/assets/characters/society-queen-nanny-avatar.png"),
  },
  {
    name: "Nefertiti",
    description: null,
    image: require("@/assets/characters/society-nefertiti-avatar.png"),
  },
  {
    name: "Ng Mui",
    description: null,
    image: require("@/assets/characters/society-ng-mui-avatar.png"),
  },
  {
    name: "Public Universal Friend",
    description: null,
    image: require("@/assets/characters/society-puf-avatar.png"),
  },
  {
    name: "Sacagawea",
    description: null,
    image: require("@/assets/characters/society-sacagawea-avatar.png"),
  },
  {
    name: "Nikola Tesla",
    description: null,
    image: require("@/assets/characters/society-tesla-avatar.png"),
  },
  {
    name: "Yasuke",
    description: null,
    image: require("@/assets/characters/society-yasuke-avatar.png"),
  },
  {
    name: "Yi Zing",
    description: null,
    image: require("@/assets/characters/society-yi-xing-avatar.png"),
  },
  {
    name: "Zenobia",
    description: null,
    image: require("@/assets/characters/society-zenobia-avatar.png"),
  },
];

export default characters;
