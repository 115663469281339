<script setup>
import { Game } from "@/classes/Cointo/Game";
import Fixed from "@/components/Fixed.vue";
import Modal from "@/components/Modal.vue";
import NavigationNew from "@/components/NavigationNew.vue";
import Overlay from "@/components/Overlay.vue";
import store from "@/store";
import { onMounted, computed, onUnmounted, ref, reactive } from "vue";

// const user = computed(() => {
//   return store.state.auth.user;
// });

const game = ref();

const getFile = (piece) => {
  let { shape, color, fill, size } = piece;
  let allFiles = {
    "circle-gold-blank-large": require("@/assets/cointo/circle-gold-blank-large.png"),
    "circle-gold-blank-small": require("@/assets/cointo/circle-gold-blank-small.png"),
    "circle-gold-logo-large": require("@/assets/cointo/circle-gold-logo-large.png"),
    "circle-gold-logo-small": require("@/assets/cointo/circle-gold-logo-small.png"),
    "circle-silver-blank-large": require("@/assets/cointo/circle-silver-blank-large.png"),
    "circle-silver-blank-small": require("@/assets/cointo/circle-silver-blank-small.png"),
    "circle-silver-logo-large": require("@/assets/cointo/circle-silver-logo-large.png"),
    "circle-silver-logo-small": require("@/assets/cointo/circle-silver-logo-small.png"),
    "square-gold-blank-large": require("@/assets/cointo/square-gold-blank-large.png"),
    "square-gold-blank-small": require("@/assets/cointo/square-gold-blank-small.png"),
    "square-gold-logo-large": require("@/assets/cointo/square-gold-logo-large.png"),
    "square-gold-logo-small": require("@/assets/cointo/square-gold-logo-small.png"),
    "square-silver-blank-large": require("@/assets/cointo/square-silver-blank-large.png"),
    "square-silver-blank-small": require("@/assets/cointo/square-silver-blank-small.png"),
    "square-silver-logo-large": require("@/assets/cointo/square-silver-logo-large.png"),
    "square-silver-logo-small": require("@/assets/cointo/square-silver-logo-small.png"),
  };

  let ret = allFiles[`${shape}-${color}-${fill}-${size}`];

  return ret;
};

const startGame = () => {
  game.value = new Game();
  window.game = game.value;
};
onMounted(async () => {
  //   window.game = game.value;
});

onUnmounted(() => {});

const setPiece = (id) => {
  game.value.selectPieceIdToPlaceNext(id);
};

const placePiece = (row, col) => {
  game.value.placePiece(row, col);
};

const getPieceById = (id) => {
  return game.value.getPieceById(id);
};

const getPlayerById = (id) => {
  return game.value.getPlayerById(id);
};

const confirmPieceIdToPlaceNext = () => {
  return game.value.confirmPieceIdToPlaceNext();
};
</script>

<template>
  <Fixed v-if="game && game.winnerId">
    <Overlay>
      <Modal>
        <div class="flex flex-col font-bold items-center justify-center">
          <div class="font-sans mb-6 text-center text-3xl">
            {{ getPlayerById(game.winnerId).name }} wins!
          </div>
          <div>
            <button
              class="btn"
              @click="
                () => {
                  game = null;
                }
              "
            >
              Start a new game
            </button>
          </div>
        </div>
      </Modal>
    </Overlay>
  </Fixed>

  <div
    v-if="game"
    class="flex flex-col h-full bg-cover items-center justify-center bg-center relative bg-ch1cream-light"
    :style="{
      'background-image': `url('${require('@/assets/cointo/bg-v1-blurred.jpg')}')`,
    }"
  >
    <div class="h-1/5 w-full">
      <div class="rotate-180 transform player-section">
        <div class="player-instruction">
          <div
            v-if="game.currentChooserId === game.playerTwo.id && !game.confirmedPieceIdToPlaceNext"
            class="player-action"
          >
            <div class="player-subinstruction">
              Choose the next piece for {{ game.playerOne.name }}
            </div>
            <button
              class="btn"
              @click="confirmPieceIdToPlaceNext"
              :disabled="!game.pieceIdToPlaceNext"
            >
              Confirm
            </button>
          </div>
          <div
            v-else-if="
              game.confirmedPieceIdToPlaceNext && game.currentPlacerId === game.playerTwo.id
            "
            class="player-action player-action-act"
          >
            Tap to place the piece
          </div>
          <div v-else class="player-action">Waiting for player 1</div>
        </div>
        <div class="player-title">Player 2</div>
      </div>
    </div>
    <div class="board-container flex-grow flex items-center w-full">
      <div class="w-1/3 h-full flex items-center justify-center p-8">
        <div class="flex flex-col justify-center items-center relative w-full h-full">
          <div class="absolute w-full h-full top-0 left-0 flex items-center justify-center">
            <img :src="require('@/assets/cointo/piece-bowl.png')" class="w-full" />
          </div>
          <div class="flex items-center justify-center flex-wrap relative p-12">
            <div
              v-for="(piece, p) in game.piecesNotPlayed"
              :key="p"
              @click="setPiece(piece.id)"
              class="flex items-center justify-center m-0 p-1 rounded-xl w-24"
              :class="{
                'bg-gray-100 bg-opacity-50 border-2 border-sothblue-light border-dashed':
                  game.pieceIdToPlaceNext === piece.id,
              }"
            >
              <div :class="[`flex items-center justify-center w-full h-full`]">
                <img :src="getFile(piece)" class="w-32" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="board w-1/3 h-full flex items-center justify-center">
        <div class="px-1 relative">
          <img
            :src="require('@/assets/cointo/cointo-board.png')"
            class="absolute top-0 left-0 w-full filter drop-shadow-lg"
          />
          <div
            v-for="(row, r) in game.board"
            :key="r"
            class="row-cells flex items-center justify-center relative"
          >
            <div
              class="cell w-32 h-32 flex items-center justify-center"
              v-for="(col, c) in row"
              :key="c"
              @click="placePiece(r, c)"
            >
              <div
                class="w-full h-full flex items-center justify-center border-sothpurple border-0"
              >
                <div v-if="col">
                  <img :src="getFile(getPieceById(col.pieceId))" class="w-24" />
                </div>
                <div
                  v-else-if="game.pieceIdToPlaceNext && game.confirmedPieceIdToPlaceNext"
                  class="w-12 h-12 bg-green-400 bg-opacity-20 rounded-full"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-1/3 h-full flex items-center justify-center">
        <div class="flex items-center justify-center w-3/4">
          <div class="text-lg relative">
            <div class="absolute w-full h-full top-0 left-0 flex items-center justify-center">
              <img :src="require('@/assets/cointo/cointo-rules.png')" class="w-full" />
            </div>
            <div class="relative p-12">
              <div class="text-3xl font-bold mb-6">How to play</div>
              <div class="mb-6">
                The goal of the game is to be the player who places a piece and creates a row,
                column, or diagonal containing 4 pieces that match on some attribute - i.e. get 4 in
                a row! The catch is that your opponent will choose which piece you place.
              </div>
              <div class="mb-6">
                There are 4 attributes with 2 types each: color (gold, or silver), shape (circle or
                square), size (big or small), and stamp (blank, or including a logo).
              </div>
              <div class="mb-6">
                Each game piece is a unique combination of the above attributes.
              </div>
              <div class="mb-6">
                Start the game with Player 1 choosing which piece Player 2 will have to place. Then
                player 2 places the piece and chooses the piece for Player 1 to place, and so on and
                so on. The game can end in a draw.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="h-1/5 w-full">
      <div class="player-section">
        <div class="player-instruction">
          <div
            v-if="game.currentChooserId === game.playerOne.id && !game.confirmedPieceIdToPlaceNext"
            class="player-action"
          >
            <div class="player-subinstruction">
              Choose the next piece for {{ game.playerTwo.name }}
            </div>
            <button
              class="btn"
              @click="confirmPieceIdToPlaceNext"
              :disabled="!game.pieceIdToPlaceNext"
            >
              Confirm
            </button>
          </div>
          <div
            v-else-if="
              game.confirmedPieceIdToPlaceNext && game.currentPlacerId === game.playerOne.id
            "
            class="player-action player-action-act"
          >
            Tap to place the piece
          </div>
          <div v-else class="player-action">Waiting for player 2</div>
        </div>
        <div class="player-title">Player 1</div>
      </div>
    </div>
  </div>
  <div v-else class="h-full w-full relative flex flex-col items-center justify-center bg-gray-300">
    <div class="flex flex-col items-center justify-center relative">
      <div class="mb-8">
        <img :src="require('@/assets/society-logo.png')" class="w-64" />
      </div>
      <div>
        <button class="game-start btn" @click="startGame">Start</button>
      </div>
      <div></div>
    </div>
  </div>
</template>

<style lang="pcss" scoped>
.player-section {
  @apply h-full mb-0 flex flex-col items-center justify-end p-4;
}

.player-instruction {
  @apply mb-6 py-4 px-3 bg-white rounded-xl bg-opacity-75 text-center flex flex-col items-center justify-center;
}

.player-title {
  @apply p-2 text-2xl font-bold font-sans bg-ch1cream rounded-xl shadow text-center w-auto;
}

.player-action {
  @apply flex items-center justify-center flex-col text-2xl text-center;
}

.player-action-act {
  @apply text-3xl text-green-500 font-bold;
}

.player-subinstruction {
  @apply text-2xl mb-4;
}
</style>
